
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "Widget1",
  components: {},
  props: {
    widgetClasses: {type: String, default: ""},
  },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const strokeColor = getCSSVariableValue("--bs-gray-300");

    const color1 = getCSSVariableValue("--bs-warning");
    const color1Light = getCSSVariableValue("--bs-light-warning");

    const color2 = getCSSVariableValue("--bs-success");
    const color2Light = getCSSVariableValue("--bs-light-success");

    const color3 = getCSSVariableValue("--bs-primary");
    const color3Light = getCSSVariableValue("--bs-light-primary");

    const options = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 2,
        colors: [color1, "transparent", "transparent"],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: strokeColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
      },
      colors: [color1, color2, color3],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        colors: [color1Light, color2Light, color3Light],
        strokeColor: [color1, color2, color3],
        strokeWidth: 3,
      },
    };

    const series = [
      {
        name: "Net Profit",
        data: [30, 30, 50, 50, 35, 35],
      },
      {
        name: "Revenue",
        data: [55, 20, 20, 20, 70, 70],
      },
      {
        name: "Expenses",
        data: [60, 60, 40, 40, 30, 30],
      },
    ];

    return {
      options,
      series,
    };
  },
});
